import { Box, Divider, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import StyledSelect from "../Styled/StyledSelect";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import StyledTextField from "../Styled/StyledTextField";
import StyledButton from "../Styled/StyledButton";
import StyledScrollbar from "../Styled/StyledScrollbar";
import ToolTip from "../UI/ToolTip";
import ToolTipMobile from "../UI/ToolTipMobile";
import { useDispatch, useSelector } from "react-redux";
import { updateTemplate } from "../../features/Template/template-actions";
import StyledCheckbox from "../Styled/StyledCheckbox";
// import StyledChartSelect from "../Styled/StyledChartSelect";

const datatypeOption = [
  {
    id: 1,
    label: "ตัวอักษร",
    value: "String",
  },
  {
    id: 2,
    label: "ตัวเลข",
    value: "Number",
  },
  {
    id: 3,
    label: "วันที่/เวลา",
    value: "Datetime",
  },
  {
    id: 4,
    label: "เดือน",
    value: "Month",
  },
  {
    id: 5,
    label: "ปี",
    value: "Year",
  },
];

const chartOption = [
  {
    id: 1,
    label: "GroupedColumn",
    value: "groupedColumn",
    img: <img alt="chart_type" src="/static/groupedColumn.png" />,
  },
  {
    id: 2,
    label: "StackedColumn",
    value: "stackedColumn",
    img: <img alt="chart_type" src="/static/stackedColumn.png" />,
  },
  {
    id: 3,
    label: "NormalizedColumn",
    value: "normalizedColumn",
    img: <img alt="chart_type" src="/static/normalizedColumn.png" />,
  },
  {
    id: 4,
    label: "GroupedBar",
    value: "groupedBar",
    img: <img alt="chart_type" src="/static/groupedBar.png" />,
  },
  {
    id: 5,
    label: "StackedBar",
    value: "stackedBar",
    img: <img alt="chart_type" src="/static/stackedBar.png" />,
  },
  {
    id: 6,
    label: "NormalizedBar",
    value: "normalizedBar",
    img: <img alt="chart_type" src="/static/normalizedBar.png" />,
  },
  {
    id: 7,
    label: "Pie",
    value: "pie",
    img: <img alt="chart_type" src="/static/pie.png" />,
  },
  {
    id: 8,
    label: "Doughnut",
    value: "doughnut",
    img: <img alt="chart_type" src="/static/doughnut.png" />,
  },
  {
    id: 9,
    label: "Line",
    value: "line",
    img: <img alt="chart_type" src="/static/line.png" />,
  },
];

const TemplateForm = ({
  control,
  errors,
  isMobile,
  reset,
  setValue,
  setOpenEdit,
  edit,
  handleSubmit,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "columns",
  });
  const boxSx = { pt: "1rem", pl: "0.875rem" };
  const { form, selectedTemplate } = useSelector((state) => state.template);
  const dispatch = useDispatch();
  const { id } = selectedTemplate;
  const [openXAxis, setOpenXAxis] = useState(false);
  const [openYAxis, setOpenYAxis] = useState(false);
  const [openDataSeries, setOpenDataSeries] = useState(false);

  useEffect(() => {
    if (reset && form && id) {
      reset(form);
    }
  }, [reset, form, id]);

  const updateTemplateHandler = (data) => {
    const input = {
      ...data,
      columns: data.columns.map((column, index) => {
        if (index === 0) {
          return {
            ...column,
            name: column.name.trim(),
            data_type: data.x_axis_type,
          };
        } else
          return {
            ...column,
            name: column.name.trim(),
            data_type: data.y_axis_type,
          };
      }),
    };
    delete input.rows;
    delete input.createdAt;
    delete input.id;
    delete input.dataId;

    dispatch(updateTemplate({ id: parseInt(id), input: input }));
    setOpenEdit(false);
  };

  const changeXaxis = (value) => {
    setValue("x_axis_type", value);
    switch (value) {
      case "Month":
        setValue("x_axis", "เดือน");
        break;
      case "Year":
        setValue("x_axis", "ปี");
        break;
      case "Datetime":
        setValue("x_axis", "วันที่");
        break;
      default:
        break;
    }
  };

  const changeYaxis = (value) => {
    setValue("y_axis_type", value);
    switch (value) {
      case "Month":
        setValue("y_axis", "เดือน");
        break;
      case "Year":
        setValue("y_axis", "ปี");
        break;
      case "Datetime":
        setValue("y_axis", "วันที่");
        break;
      default:
        break;
    }
  };

  const handleDisabled = (name) => {
    if (!name) return false;
    if (form.columns.some((column) => column.name === name)) return true;
    else return false;
  };

  const handleTooltipXAxisClose = () => {
    setOpenXAxis(false);
  };

  const handleTooltipXAxisOpen = () => {
    setOpenXAxis(true);
  };

  const handleTooltipYAxisClose = () => {
    setOpenYAxis(false);
  };

  const handleTooltipYAxisOpen = () => {
    setOpenYAxis(true);
  };

  const handleTooltipDataSeriesClose = () => {
    setOpenDataSeries(false);
  };

  const handleTooltipDataSeriesOpen = () => {
    setOpenDataSeries(true);
  };

  return (
    <>
      {!isMobile && (
        <Box>
          <Typography fontWeight="bold">ข้อมูลทั่วไป</Typography>
          <Box sx={boxSx}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => {
                return (
                  <StyledTextField
                    sx={{ width: 300 }}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    label="ชื่อรายงาน"
                    required
                    {...field}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Box display={"flex"} gap={1}>
              <Typography fontWeight="bold">แกนแนวนอน (X-Axis)</Typography>
              <ToolTip>
                <img alt="tootip-x-axis" src="/static/tooltipXaxis.png" />
              </ToolTip>
            </Box>
            <Box sx={boxSx} display={"flex"} gap={2}>
              <Controller
                name="x_axis"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    sx={{ width: 300 }}
                    error={Boolean(errors.x_axis)}
                    helperText={errors.x_axis?.message}
                    label="ชื่อแกนข้อมูล"
                    required
                    {...field}
                  />
                )}
              />
              <Controller
                name={`x_axis_type`}
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    sx={{ width: 200 }}
                    label="ประเภทแกนข้อมูล"
                    disabled={edit || false}
                    options={datatypeOption}
                    error={Boolean(errors.x_axis_type)}
                    helperText={errors.x_axis_type?.message}
                    required
                    {...field}
                    onChange={(e, val) => {
                      changeXaxis(e.target.value);
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box mt={2}>
            <Box display={"flex"} gap={1}>
              <Box display={"flex"} gap={1}>
                <Typography fontWeight="bold">แกนแนวตั้ง (Y-Axis)</Typography>
                <ToolTip>
                  <img alt="tootip-Y-axis" src="/static/tooltipYaxis.png" />
                </ToolTip>
              </Box>
            </Box>
            <Box sx={boxSx} display={"flex"} gap={2}>
              <Controller
                name="y_axis"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    sx={{ width: 300 }}
                    error={Boolean(errors.y_axis)}
                    helperText={errors.y_axis?.message}
                    label="ชื่อค่าข้อมูล"
                    required
                    {...field}
                  />
                )}
              />
              <Controller
                name={`y_axis_type`}
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    sx={{ width: 200 }}
                    label="ประเภทค่าข้อมูล"
                    disabled={edit || false}
                    options={datatypeOption}
                    error={Boolean(errors.y_axis_type && errors.y_axis_type)}
                    helperText={
                      errors.y_axis_type && errors.y_axis_type?.message
                    }
                    required
                    {...field}
                    onChange={(e, val) => {
                      changeYaxis(e.target.value);
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box mt={2}>
            <Box display={"flex"} gap={1}>
              <Typography fontWeight="bold">ชุดข้อมูล (Data Series)</Typography>
              <ToolTip>
                <img
                  alt="tootip-data-serie"
                  src="/static/tooltipDataserie.png"
                />
              </ToolTip>
            </Box>
            <form className="template-form">
              <StyledScrollbar
                sx={{
                  height: "100%",
                  width: "100%",
                  pt: 2,
                  pb: 3,
                }}
              >
                <Box display="flex" gap={2} pl={1.5}>
                  {fields.length === 0 && (
                    <StyledButton
                      sx={{ height: "fit-content" }}
                      title="เพิ่มค่าข้อมูล"
                      variant="outlined"
                      onClick={() =>
                        append({
                          name: "",
                          data_type: "String",
                          can_override_value: false,
                        })
                      }
                    >
                      <AddIcon />
                    </StyledButton>
                  )}
                  {fields.map((item, index) => {
                    if (form.columns.length !== 0)
                      return (
                        item.name !== form.columns[0].name && (
                          <Box
                            key={item.id}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Controller
                              name={`columns[${index}].name`}
                              control={control}
                              render={({ field }) => (
                                <StyledTextField
                                  sx={{ width: 220 }}
                                  disabled={Boolean(handleDisabled(item.name))}
                                  error={Boolean(
                                    errors.columns &&
                                      errors.columns[index]?.name
                                  )}
                                  helperText={
                                    errors.columns &&
                                    errors.columns[index]?.name.message
                                  }
                                  label="ชื่อคอลัมน์"
                                  {...field}
                                />
                              )}
                            />
                            {!handleDisabled(item.name) && (
                              <IconButton
                                sx={{ height: "fit-content" }}
                                color="primary"
                                aria-label="remove from list"
                                onClick={() => remove(index)}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                            {index === fields.length - 1 && (
                              <StyledButton
                                sx={{ height: "fit-content", marginLeft: 1 }}
                                title="เพิ่มค่าข้อมูล"
                                variant="outlined"
                                onClick={() =>
                                  append({
                                    name: "",
                                    data_type: "String",
                                    can_override_value: false,
                                  })
                                }
                              >
                                <AddIcon />
                              </StyledButton>
                            )}
                          </Box>
                        )
                      );
                    else {
                      return (
                        <Box
                          key={item.id}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Controller
                            name={`columns[${index}].name`}
                            control={control}
                            render={({ field }) => (
                              <StyledTextField
                                sx={{ width: 220 }}
                                error={Boolean(
                                  errors.columns && errors.columns[index]?.name
                                )}
                                helperText={
                                  errors.columns &&
                                  errors.columns[index]?.name.message
                                }
                                label="ชื่อคอลัมน์"
                                {...field}
                              />
                            )}
                          />
                          <IconButton
                            sx={{ height: "fit-content" }}
                            color="primary"
                            aria-label="remove from list"
                            onClick={() => remove(index)}
                          >
                            <CloseIcon />
                          </IconButton>
                          {index === fields.length - 1 && (
                            <StyledButton
                              sx={{ height: "fit-content", marginLeft: 1 }}
                              title="เพิ่มค่าข้อมูล"
                              variant="outlined"
                              onClick={() =>
                                append({
                                  name: "",
                                  data_type: "String",
                                  can_override_value: false,
                                })
                              }
                            >
                              <AddIcon />
                            </StyledButton>
                          )}
                        </Box>
                      );
                    }
                  })}
                </Box>
              </StyledScrollbar>
            </form>
          </Box>
          <Divider sx={{ marginY: "1rem" }} />
          <Box>
            <Typography fontWeight="bold">แสดง</Typography>
            <Box ml={2} display={"flex"} gap={1}>
              <Controller
                name="has_sum_footer"
                control={control}
                render={({ field }) => (
                  <StyledCheckbox {...field} label="ผลรวม (Sum)" />
                )}
              />
              <Controller
                name="has_avg_footer"
                control={control}
                render={({ field }) => (
                  <StyledCheckbox {...field} label="ผลเฉลี่ย (Average)" />
                )}
              />
            </Box>
          </Box>
          <Divider sx={{ marginY: "1rem" }} />
          <Box>
            <Typography fontWeight="bold" mb={2}>
              รูปแบบกราฟ
            </Typography>
            <Controller
              name={`chart_type`}
              control={control}
              render={({ field }) => (
                <StyledSelect
                  setValue={setValue}
                  sx={{ width: 250 }}
                  options={chartOption}
                  {...field}
                />
              )}
            />
          </Box>
          {id && setOpenEdit && (
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "flex-end",
                gap: ".5rem",
              }}
            >
              <StyledButton
                title="ยกเลิก"
                variant="outlined"
                onClick={() => setOpenEdit(false)}
              />
              <StyledButton
                title="บันทึก"
                variant="contained"
                onClick={handleSubmit(updateTemplateHandler)}
              />
            </Box>
          )}
        </Box>
      )}
      {isMobile && (
        <Box>
          <Typography fontWeight="bold">ข้อมูลทั่วไป</Typography>
          <Box sx={boxSx}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  sx={{ width: "92.5%" }}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  label="ชื่อรายงาน"
                  {...field}
                />
              )}
            />
          </Box>
          <Box mt={2} sx={{ width: "92.5%" }}>
            <Box display={"flex"} gap={1}>
              <Typography fontWeight="bold">แกนแนวนอน (X-Axis)</Typography>
              <ToolTipMobile
                open={openXAxis}
                handleTooltipClose={handleTooltipXAxisClose}
                handleTooltipOpen={handleTooltipXAxisOpen}
              >
                <img alt="tootip-x-axis" src="/static/tooltipXaxis.png" />
              </ToolTipMobile>
            </Box>
            <Box sx={boxSx}>
              <Controller
                name="x_axis"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    error={Boolean(errors.x_axis)}
                    helperText={errors.x_axis?.message}
                    label="ชื่อแกนข้อมูล"
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={boxSx}>
              <Controller
                name={`x_axis_type`}
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    label="ประเภทแกนข้อมูล"
                    options={datatypeOption}
                    error={Boolean(errors.x_axis && errors.x_axis)}
                    helperText={errors.x_axis && errors.x_axis?.message}
                    {...field}
                    onChange={(e, val) => {
                      changeXaxis(e.target.value);
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box mt={2} sx={{ width: "92.5%" }}>
            <Box display={"flex"} gap={1}>
              <Typography fontWeight="bold">แกนแนวตั้ง (Y-Axis)</Typography>
              <ToolTipMobile
                open={openYAxis}
                handleTooltipClose={handleTooltipYAxisClose}
                handleTooltipOpen={handleTooltipYAxisOpen}
              >
                <img alt="tootip-Y-axis" src="/static/tooltipYaxis.png" />
              </ToolTipMobile>
            </Box>
            <Box sx={boxSx}>
              <Controller
                name="y_axis"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    error={Boolean(errors.y_axis)}
                    helperText={errors.y_axis?.message}
                    label="ชื่อค่าข้อมูล"
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={boxSx}>
              <Controller
                name={`y_axis_type`}
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    label="ประเภทค่าข้อมูล"
                    options={datatypeOption}
                    error={Boolean(errors.y_axis_type && errors.y_axis_type)}
                    helperText={
                      errors.y_axis_type && errors.y_axis_type?.message
                    }
                    {...field}
                    onChange={(e, val) => {
                      changeYaxis(e.target.value);
                    }}
                  />
                )}
              />
            </Box>
          </Box>

          <Box mt={2}>
            <Box display={"flex"} gap={1}>
              <Typography fontWeight="bold">ชุดข้อมูล (Data Series)</Typography>
              <ToolTipMobile
                open={openDataSeries}
                handleTooltipClose={handleTooltipDataSeriesClose}
                handleTooltipOpen={handleTooltipDataSeriesOpen}
              >
                <img
                  alt="tootip-data-serie"
                  src="/static/tooltipDataserie.png"
                />
              </ToolTipMobile>
            </Box>
            <form>
              <Box sx={{ ...boxSx, width: "92.5%" }}>
                {fields.length === 0 && (
                  <StyledButton
                    fullWidth
                    sx={{ height: "fit-content" }}
                    title="เพิ่ม +"
                    variant="outlined"
                    onClick={() =>
                      append({
                        name: "",
                        data_type: "String",
                        can_override_value: false,
                      })
                    }
                  >
                    <AddIcon />
                  </StyledButton>
                )}
                {fields.map((item, index) => {
                  if (form.columns.length !== 0)
                    return (
                      item.name !== form.columns[0].name && (
                        <>
                          <Box key={item.id} display={"flex"} pb={1}>
                            <Controller
                              name={`columns[${index}].name`}
                              control={control}
                              render={({ field }) => (
                                <StyledTextField
                                  disabled={Boolean(handleDisabled(item.name))}
                                  error={Boolean(handleDisabled(item.name))}
                                  helperText={
                                    errors.columns &&
                                    errors.columns[index]?.message
                                  }
                                  label="ชื่อคอลัมน์"
                                  {...field}
                                />
                              )}
                            />
                            {!handleDisabled(item.name) && (
                              <IconButton
                                sx={{ position: "absolute", left: "85%" }}
                                color="primary"
                                aria-label="remove from list"
                                onClick={() => remove(index)}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </Box>
                          {index === fields.length - 1 && (
                            <StyledButton
                              fullWidth
                              title={"เพิ่ม +"}
                              color="primary"
                              variant={"outlined"}
                              aria-label="remove from list"
                              onClick={() =>
                                append({
                                  name: "",
                                  data_type: "String",
                                  can_override_value: false,
                                })
                              }
                            />
                          )}
                        </>
                      )
                    );
                  else {
                    return (
                      <>
                        <Box key={item.id} display={"flex"} pb={1}>
                          <Controller
                            name={`columns[${index}].name`}
                            control={control}
                            render={({ field }) => (
                              <StyledTextField
                                error={Boolean(
                                  errors.columns && errors.columns[index]
                                )}
                                helperText={
                                  errors.columns &&
                                  errors.columns[index]?.message
                                }
                                label="ชื่อคอลัมน์"
                                {...field}
                              />
                            )}
                          />
                          {index !== 0 && (
                            <IconButton
                              sx={{ position: "absolute", left: "85%" }}
                              color="primary"
                              aria-label="remove from list"
                              onClick={() => remove(index)}
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </Box>
                        {index === fields.length - 1 && (
                          <StyledButton
                            fullWidth
                            title={"เพิ่ม +"}
                            color="primary"
                            variant={"outlined"}
                            aria-label="remove from list"
                            onClick={() =>
                              append({
                                name: "",
                                data_type: "String",
                                can_override_value: false,
                              })
                            }
                          />
                        )}
                      </>
                    );
                  }
                })}
                {/* {fields.map((item, index) => (
                    <Box key={item.id}>
                      <Box display={"flex"} pb={1}>
                        <Controller
                          name={`columns[${index}].name`}
                          control={control}
                          render={({ field }) => (
                            <StyledTextField
                              errors={Boolean(
                                errors.columns && errors.columns[index]
                              )}
                              helperText={
                                errors.columns && errors.columns[index]?.message
                              }
                              label="ชื่อคอลัมน์"
                              {...field}
                            />
                          )}
                        />
                        {index !== 0 && (
                          <IconButton
                            sx={{ position: "absolute", left: "85%" }}
                            color="primary"
                            aria-label="remove from list"
                            onClick={() => remove(index)}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </Box>
                      {index === fields.length - 1 && (
                        <StyledButton
                          fullWidth
                          title={"เพิ่ม +"}
                          color="primary"
                          variant={"outlined"}
                          aria-label="remove from list"
                          onClick={() =>
                            append({
                              name: "",
                              data_type: "String",
                              can_override_value: false,
                            })
                          }
                        />
                      )}
                    </Box>
                  ))} */}
              </Box>
            </form>
          </Box>
          <Divider sx={{ marginY: "1rem" }} />
          <Box mt={2}>
            <Typography fontWeight="bold">แสดง</Typography>
            <Box ml={2}>
              <Controller
                name="has_sum_footer"
                control={control}
                render={({ field }) => (
                  <StyledCheckbox {...field} label="ผลรวม (Sum)" />
                )}
              />
              <Controller
                name="has_avg_footer"
                control={control}
                render={({ field }) => (
                  <StyledCheckbox {...field} label="ผลเฉลี่ย (Average)" />
                )}
              />
            </Box>
          </Box>
          <Divider sx={{ marginY: "1rem" }} />
          <Box>
            <Typography fontWeight="bold" mb={2}>
              รูปแบบกราฟ
            </Typography>
            <Controller
              name={`chart_type`}
              control={control}
              render={({ field }) => (
                <StyledSelect
                  setValue={setValue}
                  sx={{ width: 200 }}
                  options={chartOption}
                  {...field}
                />
              )}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default TemplateForm;
