import { createContext, useEffect, useReducer } from "react";
import AuthService from "../services/Auth";
import jwt from "jwt-decode";
import moment from "moment";
import Cookies from "universal-cookie";
import { useSnackbar } from "notistack";

var ActionType;
(function (ActionType) {
  ActionType["INITIALIZE"] = "INITIALIZE";
  ActionType["LOGIN"] = "LOGIN";
  ActionType["LOGOUT"] = "LOGOUT";
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const cookies = new Cookies();

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = cookies.get("access_token");
        if (accessToken) {
          const user = await AuthService.verifyToken();
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองอีกครั้งภายหลัง", {
          variant: "error",
        });
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };
    initialize();
  }, [enqueueSnackbar]);

  const login = async (email, password) => {
    const auth = await AuthService.postLogin({
      email,
      password,
    });
    const { exp } = await jwt(auth.token.access_token);
    const formatExpireDate = moment.unix(exp).format();

    cookies.set("access_token", auth.token.access_token, {
      expires: new Date(formatExpireDate),
      path: "/",
    });

    const user = auth.employee;

    dispatch({
      type: ActionType.LOGIN,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    const token = cookies.get("access_token");
    if (token) {
      try {
        await AuthService.postRevoke();
      } catch (err) {
        err.response.errors.forEach((error) => {
          console.error(error);
        });
      } finally {
        cookies.remove("access_token");
      }
    }
    dispatch({ type: ActionType.LOGOUT });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
