import { createSlice } from "@reduxjs/toolkit";
export const REDIRECT = "REDIRECT";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarType: "",
  },
  reducers: {
    openSnackbar(state, action) {
      state.snackbarType = action.payload.is_success;
      state.snackbarMessage = action.payload.message;
      state.snackbarOpen = true;
    },
    closeSnackbar(state, action) {
      state.snackbarMessage = "";
      state.snackbarOpen = false;
      state.infoSnackbarOpen = false;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;

//  reducers/uiReducer.js
// const uiReducer = (state = {}, action) => {
//   switch (action.type) {
//     case "SNACKBAR":
//       return {
//         ...state,
//         snackbarOpen: true,
//         snackbarMessage: action.message,
//         snackbarType: action.is_success,
//       };
//     case "SNACKBAR_CLEAR":
//       return {
//         ...state,
//         snackbarOpen: false,
//         infoSnackbarOpen: false,
//       };
//     default:
//       return state;
//   }
// };

// export default uiReducer;
