import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./rbac-initial";

const rbacSlice = createSlice({
  name: "rbac",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllRoles(state, action) {
      state.allRoles = action.payload;
      state.isLoading.allRoles = false;
    },
    appendedNewRole(state, action) {
      state.allRoles = [...state.allRoles, action.payload];
    },
    loadedPriviledgeToRole(state, action) {
      const payload = action.payload;
      if (
        ["VIEW", "CREATE", "EDIT", "DELETE"].includes(payload["PRIVILEDGE"])
      ) {
        state.role[payload["RESOURCE"]][payload["FUNCTION"]][
          payload["PRIVILEDGE"]
        ] = true;
      }
    },
    removedPriviledgeFromRole(state, action) {
      const payload = action.payload;
      if (
        ["VIEW", "CREATE", "EDIT", "DELETE"].includes(payload["PRIVILEDGE"])
      ) {
        state.role[payload["RESOURCE"]][payload["FUNCTION"]][
          payload["PRIVILEDGE"]
        ] = false;
      }
    },
    loadedPriviledgeList(state, action) {
      state.priviledgeList = action.payload;
      state.isLoading.priviledgeList = false;
    },
    resetRole(state) {
      state.role = initialState.role;
      state.priviledgeList = initialState.priviledgeList;
    },
    resetAllRole(state) {
      state.allRoles = initialState.allRoles;
    },
    loadedRole(state, action) {
      state.role = { ...state.role, ...action.payload };
      state.isLoading.role = false;
    },
    updatedRole(state, action) {
      const updatedRole = action.payload;
      const updatedIndex = state.allRoles.findIndex(
        (role) => role.id === updatedRole.id
      );
      state.allRoles[updatedIndex] = updatedRole;
      state.isLoading.allRoles = false;
    },
    deletedRole(state, action) {
      state.allRoles = state.allRoles.filter(
        (role) => role.id !== action.payload.id
      );
      state.isLoading.role = false;
    },
  },
});

export const rbacActions = rbacSlice.actions;

export default rbacSlice.reducer;
