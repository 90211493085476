import * as React from "react";
import Avatar from "@mui/material/Avatar";

function stringAvatar(name) {
  return {
    sx: {
      color: "#1C5026",
      background: "rgba(28, 80, 38, 0.1)",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export default function StyledLetterAvatar({ name }) {
  return <Avatar {...stringAvatar(name)} />;
}
