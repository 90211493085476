import moment from "moment";

export const customChartThemes = (
  formatData,
  title,
  xAxis,
  yAxis,
  min,
  max,
  columns
) => {
  const chartColorsList = [
    "#88bcf1",
    "#eba9a3",
    "#eff4cd",
    "#abede5",
    "#efc5a9",
    "#d2efd8",
    "#aea298",
    "#fbe0e8",
    "#a51fff",
    "#707070",
    "#6e94d3",
    "#6fc8c6",
    "#1871cb",
    "#cd3b2d",
    "#cad956",
    "#30d3bf",
    "#d8712c",
    "#65c87a",
    "#63574e",
    "#e95d8b",
    "#54008c",
    "#262626",
    "#284b85",
    "#2d7775",
  ];

  let usedPalette = [];
  columns?.forEach((_, index) => {
    if (index < chartColorsList.length) {
      usedPalette.push(chartColorsList[index]);
    } else {
      let currIndex = index % chartColorsList.length;
      usedPalette.push(chartColorsList[currIndex]);
    }
  });

  return {
    myCustomTheme: {
      baseTheme: "ag-theme-material",
      palette: {
        fills: usedPalette.length > 0 ? usedPalette : chartColorsList,
        strokes: usedPalette.length > 0 ? usedPalette : chartColorsList,
      },
      overrides: {
        common: {
          legend: {
            position: "top",
          },
          axes: {
            number: {
              // min: 0,
              // min: min ?? undefined,
              // max: max ?? undefined,
              nice: true,
              tick: {
                // count: 5,
                width: 0,
              },
              title: {
                enabled: true,
                text: yAxis,
              },
              label: {
                formatter: function (params) {
                  if (params.value) return params.value.toLocaleString();
                  else return params.value;
                },
              },
            },
            category: {
              title: {
                enabled: true,
                text: xAxis,
              },
              label: {
                rotation: 45,
                formatter: (params) => {
                  if (!formatData) return params.value.value;
                  return moment(new Date(params.value)).format(formatData);
                },
              },
            },
          },
        },
        column: {
          series: {
            strokeWidth: 0,
            highlightStyle: {
              item: {
                fill: undefined,
              },
              series: {
                dimOpacity: 0.2,
              },
            },
            visible: true,
            // flipXY: true,
            label: {
              // placement: "outside",
              enabled: true,
              fontWeight: "normal",
              fontSize: 10,
              formatter: function (params) {
                if (params.value) return params.value.toLocaleString();
                else return params.value;
              },
            },
            tooltip: {
              renderer: (params) => {
                return {
                  content:
                    params.xValue +
                    ":</b> " +
                    parseFloat(params.yValue).toLocaleString(),
                };
              },
            },
          },
          navigator: {
            enabled: true,
            height: 50,
            minHandle: {
              width: 20,
              height: 30,
            },
            maxHandle: {
              width: 20,
              height: 30,
            },
          },
        },
        bar: {
          series: {
            strokeWidth: 0,
            highlightStyle: {
              item: {
                fill: undefined,
              },
              series: {
                dimOpacity: 0.2,
              },
            },
            label: {
              // placement: "outside",
              enabled: true,
              fontWeight: "normal",
              fontSize: 10,
              formatter: function (params) {
                if (params.value) return params.value.toLocaleString();
                else return params.value;
              },
            },
          },
          navigator: {
            enabled: true,
            height: 50,
            minHandle: {
              width: 20,
              height: 30,
            },
            maxHandle: {
              width: 20,
              height: 30,
            },
          },
        },
        line: {
          series: {
            highlightStyle: {
              item: {
                fill: undefined,
              },
              series: {
                dimOpacity: 0.2,
              },
            },
            label: {
              enabled: true,
              fontWeight: "normal",
              fontSize: 10,
              formatter: function (params) {
                if (params.value) return params.value.toLocaleString();
                else return params.value;
              },
            },
          },
          navigator: {
            enabled: true,
            height: 50,
            minHandle: {
              width: 20,
              height: 30,
            },
            maxHandle: {
              width: 20,
              height: 30,
            },
          },
        },
        pie: {
          series: {
            strokeWidth: 0,
            highlightStyle: {
              item: {
                fill: undefined,
              },
              series: {
                dimOpacity: 0.2,
              },
            },
            label: {
              // placement: "outside",
              enabled: true,
              fontWeight: "normal",
              fontSize: 10,
              formatter: function (params) {
                if (params.value) return params.value.toLocaleString();
                else return params.value;
              },
            },
          },
        },
      },
    },
  };
};
