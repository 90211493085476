import { uiActions } from "./ui-slice";

export const showSnackbar = (is_success, message) => (dispatch) => {
  dispatch(uiActions.openSnackbar({ is_success, message }));
};

export const clearSnackbar = () => {
  return (dispatch) => {
    dispatch(uiActions.closeSnackbar());
  };
};
