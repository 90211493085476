import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  validation,
  schema,
} from "../../../components/Form/UserAccount/schema";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useNavbar } from "../../../hooks/use-navbar";
import { useTranslation } from "react-i18next";
import { Box, useMediaQuery } from "@mui/material";
import DesktopLayout from "../../../components/Layout/DesktopLayout";
import AccountTab from "./AccountTab";
import PermissionTab from "./PermissionTab";
import StyledTab from "../../../components/Styled/StyledTab";
import MobileLayout from "../../../components/Layout/MobileLayout";
import ActionBar from "../../../components/UI/ActionBar";
import {
  getUser,
  getUserSetting,
  postNewUser,
  updateUser,
  updateUserSetting,
} from "../../../features/User/Account/account-actions";
import { useAuth } from "../../../hooks/use-auth";
import { rbacActions } from "../../../features/User/Rbac/rbac-slice";
import { accountActions } from "../../../features/User/Account/account-slice";
import { useSnackbar } from "notistack";
import { uploadFileToS3 } from "../../../utils/s3";
import { getAllRoles } from "../../../features/User/Rbac/rbac-action";
import StyledModal from "../../../components/Styled/StyledModal";
import StyledButton from "../../../components/Styled/StyledButton";
import StyledTextField from "../../../components/Styled/StyledTextField";

const UserContainer = ({ viewOnly, addable, setting }) => {
  const { user } = useAuth();
  const isCreatable = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("ADMIN__ADMIN__ADMIN") ||
      role?.permission_list?.includes("CONFIG__USER__CREATE")
  );

  const isEditable = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("CONFIG__USER__EDIT") ||
      role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );
  const { enqueueSnackbar } = useSnackbar();

  const [confirmation, setConfirmation] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mobile } = useNavbar();
  const { t } = useTranslation();
  const { employeeId } = useParams();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const {
    role,
    allRoles,
    isLoading: rbacIsLoading,
  } = useSelector((state) => state.rbac);

  const { user: employee, isLoading: accountIsLoading } = useSelector(
    (state) => state.account
  );

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: { ...employee, ...role },
    resolver: yupResolver(validation),
  });

  const onUserSubmit = async (data, type) => {
    if (type !== "triggerRevalidate") {
      let img_url = "";
      if (data.img_url && typeof data.img_url === "object") {
        const { Location } = await uploadFileToS3(
          data.img_url,
          "user",
          setting ? user.id : employeeId
        );
        img_url = Location;
      }

      const role_list = allRoles
        .filter((role) => data.group_role_list.includes(role.name))
        .map((role) => role.id);

      const updateEmployeeRole = {
        id: setting ? user.id : parseInt(employeeId),
        role_list: role_list,
      };

      const newUserPayload = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        img_url: data.img_url
          ? typeof data.img_url === "object"
            ? img_url
            : data.img_url
          : null,
        phone: data.phone.toString(),
        position: data.position,
        department: data.department,
        password: data.password,
      };

      const updateUserSelfInput = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        img_url: data.img_url
          ? typeof data.img_url === "object"
            ? img_url
            : data.img_url
          : null,
        phone: data.phone.toString(),
        position: data.position,
        department: data.department,
        old_password: data.confirm_password || data.old_password,
      };

      const updateUserInput = {
        id: setting ? user.id : parseInt(employeeId),
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        img_url: data.img_url
          ? typeof data.img_url === "object"
            ? img_url
            : data.img_url
          : null,
        phone: data.phone.toString(),
        position: data.position,
        department: data.department,
        is_active: data.is_active === "true" || true ? true : false,
        password: data.password,
      };

      let passwordInput;

      if (data.old_password && data.new_password) {
        passwordInput = {
          oldPassword: data.old_password,
          newPassword: data.new_password,
        };
      }

      if (employeeId && (isCreatable || isEditable)) {
        //case : update existing user
        dispatch(
          updateUser(
            updateUserInput,
            passwordInput,
            updateEmployeeRole,
            enqueueSnackbar,
            navigate
          )
        );
      } else if (setting) {
        dispatch(
          updateUserSetting(updateUserSelfInput, passwordInput, enqueueSnackbar)
        );
        setConfirmation(false);
        setValue("confirm_password", "");
      } else {
        //case : create new user
        dispatch(
          postNewUser(newUserPayload, role_list, navigate, enqueueSnackbar)
        );
      }
    }
  };

  const breadcrumbs = [
    {
      name: t("user.account.index"),
      href: "/user",
    },
    {
      name: setting ? t("user.config") : t("user.index"),
      href: setting ? "/user/config" : "/user/admin",
    },
    !setting && {
      name: employee?.id && employee?.first_name + " " + employee?.last_name,
    },
  ];

  const formatBreadcrumbs = breadcrumbs.filter(
    (breadcrumb) => breadcrumb !== false
  );

  const tabs = [
    {
      label: t("user.index"),
      path: `${pathname}`,
    },
    {
      label: t("user.account.permission"),
      path: `${pathname}?step=permission&subtab=template`,
    },
  ];

  useEffect(() => {
    mobile.setMobileTitle(t("user.index"));
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile, t]);

  useEffect(() => {
    //use new method
    dispatch(getAllRoles(enqueueSnackbar));

    return () => {
      dispatch(rbacActions.resetRole());
      dispatch(accountActions.resetUser());
    };
  }, [dispatch, employeeId, enqueueSnackbar]);

  useEffect(() => {
    //get data to be rendered as default values
    if (employeeId) {
      const getUserPayload = setting ? user.id : parseInt(employeeId);
      dispatch(getUser(getUserPayload, enqueueSnackbar));
    } else if (setting) {
      dispatch(getUserSetting(enqueueSnackbar));
    }
  }, [dispatch, employeeId, enqueueSnackbar, setting, user]);

  useEffect(() => {
    //replace default values of form hook with new value from redux
    if (employee) {
      Object.entries(employee).forEach(([key, value]) => setValue(key, value));
    }
  }, [employee, setValue]);

  const currentTab =
    pathname +
    (step
      ? `?step=${step}${step === "permission" ? "&subtab=template" : ""}`
      : "");

  const onButtonClicked = async (data) => {
    onUserSubmit(data, "triggerRevalidate");
    const validInput = await trigger(Object.keys(schema));
    if (validInput) navigate(`${pathname}?step=permission&subtab=template`);
  };

  const buttons = [
    !step &&
      (setting || isEditable) && {
        type: "button",
        title: "ดำเนินต่อ",
        variant: "contained",
        onClick: handleSubmit(onButtonClicked),
      },
    step === "permission" && {
      type: "button",
      title: t("button.save"),
      variant: "contained",
      ...(pathname.includes("admin") || getValues("old_password")
        ? {
            onClick: handleSubmit(onUserSubmit),
          }
        : {
            onClick: () => setConfirmation(true),
          }),
    },
  ];

  return (
    <>
      {!isMobile && (
        <DesktopLayout
          isSidebarOpen
          pathname={setting ? "/user" : "/user/admin"}
        >
          <form>
            <ActionBar
              breakcrumbs={formatBreadcrumbs}
              buttons={buttons}
              isLoading={rbacIsLoading.allRoles || accountIsLoading.user}
              isSidebarOpen
            />
            <StyledTab tabs={tabs} currentTab={currentTab} />
            {!step ? (
              <AccountTab
                control={control}
                errors={errors}
                setValue={setValue}
                trigger={trigger}
                viewOnly={viewOnly}
                isCreatable={isCreatable}
                isEditable={isEditable}
                getValues={getValues}
                setting={setting}
              />
            ) : (
              <PermissionTab
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                viewOnly={viewOnly || setting}
                addable={addable}
                isCreatable={isCreatable}
                isEditable={isEditable}
              />
            )}
          </form>
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout
          isLoading={rbacIsLoading.allRoles || accountIsLoading.user}
          pathname={setting ? "/user" : "/user/admin"}
        >
          <Box sx={{ my: 2 }}>
            <form>
              <StyledTab tabs={tabs} currentTab={currentTab} />
              {!step ? (
                <AccountTab
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  trigger={trigger}
                  viewOnly={viewOnly}
                  isCreatable={isCreatable}
                  isEditable={isEditable}
                  getValues={getValues}
                  setting={setting}
                  onTriggerRevalidate={handleSubmit(onButtonClicked)}
                  isMobile
                />
              ) : (
                <PermissionTab
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  viewOnly={viewOnly || setting}
                  addable={addable}
                  isCreatable={isCreatable}
                  isEditable={isEditable}
                  onSubmit={
                    pathname.includes("admin") || getValues("old_password")
                      ? handleSubmit(onUserSubmit)
                      : () => setConfirmation(true)
                  }
                  isMobile
                />
              )}
            </form>
            {/* <MobileExport ref={gridRef} /> */}
          </Box>
        </MobileLayout>
      )}
      <StyledModal
        maxWidth="xs"
        closeHandler={() => {
          setConfirmation(false);
          setValue("confirm_password", "");
        }}
        title="กรุณายืนยันรหัสผ่านของคุณ"
        open={confirmation}
        action={
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: ".5rem" }}
          >
            <StyledButton
              title="ยกเลิก"
              variant="outlined"
              onClick={() => {
                setConfirmation(false);
                setValue("confirm_password", "");
              }}
            />
            <StyledButton
              title="ยืนยัน"
              variant="contained"
              onClick={handleSubmit(onUserSubmit)}
            />
          </Box>
        }
        content={
          <Controller
            name="confirm_password"
            control={control}
            render={({ field }) => (
              <StyledTextField {...field} type="password" />
            )}
          />
        }
      />
    </>
  );
};

export default UserContainer;
