import UserService from "../../../services/User";
import { errorMessageHandler } from "../../../utils/data-transformer";
import { accountActions } from "./account-slice";

export const getAllUsers = (enqueueSnackbar) => async (dispatch) => {
  dispatch(accountActions.onLoading("allUsers"));
  try {
    const allUsers = await UserService.getAllUsers();
    dispatch(accountActions.loadedAllUsers(allUsers));
  } catch (err) {
    dispatch(accountActions.rejectedActions({ ...err, name: "allUsers" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const postNewUser =
  (createEmployeeInput, roleList, navigate, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(accountActions.onLoading("user"));
    try {
      const newUser = await UserService.postNewUser(createEmployeeInput);
      const updateEmployeeRole = {
        id: newUser.id,
        role_list: roleList,
      };
      await UserService.updateUserRole(updateEmployeeRole);
      dispatch(accountActions.appendedNewUser(newUser));
      navigate("/user/admin");
      enqueueSnackbar("เพิ่มผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      dispatch(accountActions.rejectedActions({ ...err, name: "user" }));
      enqueueSnackbar("เพิ่มผู้ใช้งานไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const getUser = (uniqueInput, enqueueSnackbar) => async (dispatch) => {
  dispatch(accountActions.onLoading("user"));
  try {
    const user = await UserService.getUser(uniqueInput);
    dispatch(accountActions.loadedUser(user));
  } catch (err) {
    dispatch(accountActions.rejectedActions({ ...err, name: "user" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const getUserSetting = (enqueueSnackbar) => async (dispatch) => {
  dispatch(accountActions.onLoading("user"));
  try {
    const user = await UserService.getUserSetting();
    dispatch(accountActions.loadedUser(user));
  } catch (err) {
    dispatch(accountActions.rejectedActions({ ...err, name: "user" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const updateUser =
  (
    updateEmployeeInput,
    passwordInput,
    updateEmployeeRole,
    enqueueSnackbar,
    navigate
  ) =>
  async (dispatch) => {
    dispatch(accountActions.onLoading("allUsers"));
    try {
      await UserService.updateUser(updateEmployeeInput);
      if (passwordInput) {
        await UserService.updatePassword(passwordInput);
      }
      const appendedRole = await UserService.updateUserRole(updateEmployeeRole);
      dispatch(accountActions.updatedUser(appendedRole));
      enqueueSnackbar("แก้ไขผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
      navigate("/user/admin");
    } catch (err) {
      const formatError = err.response.errors;
      dispatch(
        accountActions.rejectedActions({ formatError, name: "allUsers" })
      );
      enqueueSnackbar("แก้ไขผู้ใช้งานไม่สำเร็จ", {
        variant: "error",
      });
      console.log(err);
    }
  };

export const updateUserSetting =
  (updateEmployeeSelfInput, passwordInput, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(accountActions.onLoading("user"));
    try {
      const user = await UserService.updateUserSetting(updateEmployeeSelfInput);
      if (passwordInput) {
        await UserService.updatePassword(passwordInput);
      }
      dispatch(accountActions.updatedUser(user));
      enqueueSnackbar("แก้ไขผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      dispatch(accountActions.rejectedActions({ ...err, name: "user" }));
      err.response.errors.forEach((error) => {
        enqueueSnackbar(errorMessageHandler(error.message), {
          variant: "error",
        });
      });
    }
  };

export const disableUser = (uniqueInput) => async (dispatch) => {
  dispatch(accountActions.onLoading("allUsers"));
  try {
    const disabledUser = await UserService.disableUser(uniqueInput);
    dispatch(accountActions.disabledUser(disabledUser));
  } catch (err) {
    dispatch(accountActions.rejectedActions({ ...err, name: "allUsers" }));
  }
};
