import { useMediaQuery, Box } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DesktopLayout from "../../../components/Layout/DesktopLayout";
import LandscapeLayout from "../../../components/Layout/LandscapeLayout";
import MobileLayout from "../../../components/Layout/MobileLayout";
import StyledTab from "../../../components/Styled/StyledTab";
import ActionBar from "../../../components/UI/ActionBar";
import AgGrid from "../../../components/UI/AgGrid";
import CustomDate from "../../../components/UI/CustomDate";
import { getAllNavision } from "../../../features/Navision/navision-actions";
import { useAuth } from "../../../hooks/use-auth";
import { useNavbar } from "../../../hooks/use-navbar";
import {
  filterParams,
  mapNameToPermission,
} from "../../../utils/data-transformer";

const NavisionReport = () => {
  const gridRef = useRef();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { mobile } = useNavbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });
  const { isLoading, allNavisions } = useSelector((state) => state.navision);
  const isAdmin = user?.role_list?.some((role) =>
    role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );

  const breadcrumbs = [
    {
      name: t("report.index"),
      href: "/report",
    },
    {
      name: t("report.navision.index"),
    },
  ];

  const tabs = [
    {
      label: t("report.navision.index"),
      path: "/report/navision",
    },
    {
      label: t("report.template.index"),
      path: "/report/template",
    },
  ];

  const columns = [
    {
      field: "navision_name",
      headerName: t("report.navision.reportName"),
      width: 300,
    },
    {
      field: "navision_last_updated",
      headerName: t("report.navision.lastUpdated"),
      filter: "agMultiColumnFilter",
      filterParams: filterParams("Datetime"),
    },
    {
      field: "navision_date",
      headerName: t("report.navision.dataDate"),
      filter: "agMultiColumnFilter",
      filterParams: filterParams("Datetime"),
    },
  ];

  const getRowStyle = () => {
    return { cursor: "pointer" };
  };

  const onRowClicked = (event) => {
    switch (event.data.navision_name) {
      case "รายงานยอดขายตามผลิตภัณฑ์":
        return navigate(`${pathname}/sales`);
      case "รายงานยอดขายตามผู้แทนขาย":
        return navigate(`${pathname}/sales-person`);
      case "รายงานยอดขายตามภาค":
        return navigate(`${pathname}/sales-region`);
      case "รายงานลูกค้า":
        return navigate(`${pathname}/customer`);
      case "ยอด AR และ Overdue ลูกค้า":
        return navigate(`${pathname}/ar`);
      case "ยอด AP และ Overdue Supplier":
        return navigate(`${pathname}/ap`);
      default:
        return;
    }
  };

  useEffect(() => {
    dispatch(getAllNavision());
  }, [dispatch]);

  useEffect(() => {
    mobile.setMobileTitle(t("report.navision.index"));
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile, t]);

  const components = useMemo(() => {
    return {
      agDateInput: CustomDate,
    };
  }, []);

  const isLandscape = useMediaQuery(
    "(max-device-width: 920px) and (orientation: landscape)",
    {
      noSsr: true,
    }
  );
  let allPermissions = [];

  user?.role_list?.forEach((role) => {
    allPermissions.push(...role.permission_list);
  });

  const filteredNavision = allNavisions?.filter((navision) =>
    allPermissions.includes(mapNameToPermission(navision.navision_name))
  );

  return (
    <>
      {!isMobile && !isLandscape && (
        <DesktopLayout isSidebarOpen pathname="/report">
          <ActionBar
            ref={gridRef}
            breakcrumbs={breadcrumbs}
            isSidebarOpen
            isLoading={isLoading.allNavisions}
          />
          <StyledTab tabs={tabs} currentTab={pathname} />
          <AgGrid
            ref={gridRef}
            columnDefs={columns}
            rowData={isAdmin ? allNavisions : filteredNavision}
            getRowStyle={getRowStyle}
            onRowClicked={onRowClicked}
            components={components}
            searchBox
            height={420}
          />
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout pathname="/report">
          <Box sx={{ width: "100%", mt: 2 }}>
            <StyledTab
              tabs={tabs}
              currentTab={pathname}
              centered={isMobile}
              divider
            />
            <AgGrid
              ref={gridRef}
              columnDefs={columns}
              rowData={isAdmin ? allNavisions : filteredNavision}
              onRowClicked={onRowClicked}
              height={420}
              components={components}
              searchBox
              isMobile
            />
          </Box>
        </MobileLayout>
      )}
      {!isMobile && isLandscape && (
        <LandscapeLayout>
          <Box sx={{ my: 2 }}>
            <ActionBar breakcrumbs={breadcrumbs} isLandscape />
            <StyledTab
              tabs={tabs}
              currentTab={pathname}
              centered={isMobile}
              divider
            />
            <AgGrid
              ref={gridRef}
              columnDefs={columns}
              rowData={isAdmin ? allNavisions : filteredNavision}
              onRowClicked={onRowClicked}
              height={420}
              components={components}
              searchBox
              isLandscape
            />
          </Box>
        </LandscapeLayout>
      )}
    </>
  );
};

export default NavisionReport;
