import { Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import StyledBox from "../../../components/Styled/StyledBox";
import StyledChip from "../../../components/Styled/StyledChip";
import StyledComboBox from "../../../components/Styled/StyledComboBox";
import { Controller, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  mapRolesToObject,
  extractRoleNames,
} from "../../../utils/data-transformer";
import {
  removePriviledge,
  setPriviledgeList,
} from "../../../features/User/Rbac/rbac-action";
import PermissionForm from "../../../components/Form/Permission";
import { useNavigate } from "react-router-dom";
import StyledButton from "../../../components/Styled/StyledButton";

const PermissionTab = ({
  control,
  setValue,
  getValues,
  viewOnly,
  addable,
  isCreatable,
  isEditable,
  isMobile,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role, allRoles } = useSelector((state) => state.rbac);
  const { user } = useSelector((state) => state.account);

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "group_role_list", // unique name for your Field Array
  });

  const chipOptions = extractRoleNames(allRoles);

  const handleAddRole = useCallback(
    (newValue) => {
      if (newValue && !getValues("group_role_list").includes(newValue)) {
        append(newValue);
        const selectedRoleNames = getValues("group_role_list");
        const priviledgeListPayload = mapRolesToObject(
          allRoles,
          selectedRoleNames
        );
        dispatch(setPriviledgeList(priviledgeListPayload));
      }
    },
    [allRoles, append, dispatch, getValues]
  );

  const handleDeleteRole = (roleIndex, data) => {
    remove(roleIndex);
    delete data["id"]; // remove unused key from chip data

    const deletedRoleName = Object.values(data).join(""); // get name of the deleted role
    const selectedRoleNames = getValues("group_role_list");

    const removePriviledgePayload = allRoles.find(
      (role) => role.name === deletedRoleName
    ).permission_list;
    dispatch(removePriviledge(removePriviledgePayload));

    const priviledgeListPayload = mapRolesToObject(allRoles, selectedRoleNames);
    dispatch(setPriviledgeList(priviledgeListPayload));
  };

  const handleChipClicked = (chipIndex) => {
    const roleName = getValues("group_role_list")[chipIndex];
    const roleId = allRoles.find((role) => role.name === roleName).id;
    navigate(`/user/rbac/${roleId}`);
  };

  /* should not be reset since the process not yet end
  useEffect(() => {
   return () => {
       dispatch(rbacActions.resetRole());
   };
  }, [dispatch]);
  */

  useEffect(() => {
    if (user) {
      user.role_list
        ?.map((role) => role.name)
        .forEach((roleName) => handleAddRole(roleName));
    }
  }, [handleAddRole, user]);

  useEffect(() => {
    //set new default value for inputs in the table if role change
    if (role) {
      Object.entries(role).forEach(([key, value]) => setValue(key, value));
    }
  }, [role, setValue]);

  return (
    <>
      <StyledBox sx={{ my: 2 }}>
        <Typography fontWeight="bold" ml={1} mb={2}>
          {t("user.account.permission")}
        </Typography>
        <Grid container spacing={1}>
          {(!viewOnly || isCreatable || isEditable) && (
            <Grid item xs={12} md={4}>
              <StyledComboBox
                label={t("user.rbac.permission")}
                options={chipOptions}
                onChange={(_, newValue) => handleAddRole(newValue)}
              />
            </Grid>
          )}
          <Grid item xs={12} md={8} container spacing={1} alignItems="center">
            {fields.map((data, index) => (
              <Controller
                key={index + Math.random()}
                name={`group_role_list[${index}]`}
                control={control}
                render={({ field }) => (
                  <Grid item>
                    <StyledChip
                      onDelete={
                        isCreatable || isEditable
                          ? () => handleDeleteRole(index, data)
                          : undefined
                      }
                      onClick={() => handleChipClicked(index)}
                      {...field}
                    />
                  </Grid>
                )}
              />
            ))}
          </Grid>
        </Grid>
      </StyledBox>
      <PermissionForm control={control} addable={addable} />
      {isMobile && (
        <StyledButton
          fullWidth
          title={t("button.save")}
          variant="contained"
          onClick={onSubmit}
        />
      )}
    </>
  );
};

export default PermissionTab;
