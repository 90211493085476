import { GraphQLClient } from "graphql-request";
import Cookies from "universal-cookie";

const cookies = new Cookies();

let endpoint;
if (process.env.REACT_APP_ENVIRONMENT.trim() === "staging") {
  endpoint = "https://data-dev-api.tpcc.co.th/graphql";
  console.log("running on staging");
}
if (process.env.REACT_APP_ENVIRONMENT.trim() === "development") {
  endpoint = "https://data-dev-api.tpcc.co.th/graphql";
  console.log("running on development");
} else if (process.env.REACT_APP_ENVIRONMENT.trim() === "production") {
  endpoint = "https://data-api.tpcc.co.th/graphql";
  console.log("running on production");
}

export const graphQLClient = new GraphQLClient(endpoint, {
  credentials: "include",
  mode: "cors",
});

export const graphQLClientWithHeader = () => {
  let token = cookies.get("access_token");
  graphQLClient.setHeader("authorization", `Bearer ${token}`);
  return graphQLClient;
};
