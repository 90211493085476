import React, { forwardRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const StyledComboBox = forwardRef(
  ({ options, label, sx, name, onChange, value, disabled, error }, ref) => {
    return (
      <Autocomplete
        disablePortal
        value={value}
        options={options}
        onChange={onChange}
        getOptionLabel={(option) => option.name || option}
        isOptionEqualToValue={(option, value) => {
          if (option.name)
            return option.id === value.id || option.name === value;
          else return option === value;
        }}
        renderOption={(props, option) => {
          if (option.id || option.name) {
            return (
              <li {...props} value={option.id} key={option.id}>
                {option.name}
              </li>
            );
          } else {
            return (
              <li {...props} value={option} key={option}>
                {option}
              </li>
            );
          }
        }}
        sx={sx}
        ref={ref}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size="small"
            name={name}
            error={error}
          />
        )}
        autoComplete
        includeInputInList
        disabled={disabled}
      />
    );
  }
);

export default StyledComboBox;
