import { Box, useMediaQuery } from "@mui/material";
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/th";
import DesktopLayout from "../../../components/Layout/DesktopLayout";
import MobileLayout from "../../../components/Layout/MobileLayout";
import ActionBar from "../../../components/UI/ActionBar";
import AgGrid from "../../../components/UI/AgGrid";
import { filterParams, toLocale } from "../../../utils/data-transformer";
import TemplateImporter from "../../../components/UI/TemplateImporter";
import StyledModal from "../../../components/Styled/StyledModal";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTemplate,
  getTemplateById,
  removeTemplateDatum,
} from "../../../features/Template/template-actions";
import { templateActions } from "../../../features/Template/template-slice";
import { createTemplate } from "../../../features/Template/template-actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import LandscapeLayout from "../../../components/Layout/LandscapeLayout";
import { useNavbar } from "../../../hooks/use-navbar";
import CustomMonth from "../../../components/UI/CustomMonth";
import CustomDate from "../../../components/UI/CustomDate";
import CustomYear from "../../../components/UI/CustomYear";
import DeleteReportModal from "../../../components/UI/DeleteModal";
import DeleteDataModal from "../../../components/UI/DeleteDataModal";
import EditModal from "../../../components/UI/EditModal";
import CopyModal from "../../../components/UI/CopyModal";
import MobileExport from "../../../components/UI/MobileExport";
import StyledButton from "../../../components/Styled/StyledButton";
import ShareModal from "../../../components/UI/ShareModal";
import { useAuth } from "../../../hooks/use-auth";

const TemplateDetail = () => {
  const gridRef = useRef();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mobile } = useNavbar();
  const [open, setOpen] = useState(false);
  const [dataSelector, setDataSelector] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [confirmationRemoveButton, setConfirmationRemoveButton] =
    useState(false);
  const { selectedTemplate, isLoading, sumSelectedTemplateData } = useSelector(
    (state) => state.template
  );
  const { user } = useAuth();
  const [share, setShare] = useState(false);

  const allTemplateList = [];
  const allPermissions = [];

  user?.role_list?.forEach((template) => [
    allTemplateList.push(...template.accessible_template_list),
  ]);

  user?.role_list?.forEach((role) => {
    allPermissions.push(...role.permission_list);
  });

  const allTemplatePriviledge = allTemplateList
    .filter((templateRole) => templateRole.template_id === parseInt(id))
    .map((template) => template.template_priviledge);

  const isViewer = allTemplatePriviledge.includes("viewer");

  const isEditor = allTemplatePriviledge.includes("editor");

  const isManager = allTemplatePriviledge.includes("manager");

  const isAdmin = allPermissions.includes("ADMIN__ADMIN__ADMIN");

  const { rows, columns, chart_type, has_avg_footer, has_sum_footer } =
    selectedTemplate;

  const schema = Yup.object().shape({
    name: Yup.string().required("กรุณากรอก"),
    x_axis: Yup.string()
      .required("กรุณาระบุแกน X")
      .typeError("กรุณาระบุเป็นวันที่ ex.20XX-XX-XX"),
    x_axis_type: Yup.string().required("กรุณาเลือกแกนข้อมูล"),
    y_axis: Yup.string().required("กรุณากรอกแกน Y"),
    y_axis_type: Yup.string().required("กรุณาเลือกค่าข้อมูล"),
    columns: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("กรุณาระบุชื่อคอลัมน์"),
      })
    ),
  });

  const { form } = useSelector((state) => state.template);
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: form,
    resolver: yupResolver(schema),
  });

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });

  const isLandscape = useMediaQuery(
    "(max-device-width: 920px) and (orientation: landscape)",
    {
      noSsr: true,
    }
  );

  const breadcrumbs = [
    {
      name: t("report.index"),
      href: "/report",
    },
    {
      name: t("report.template.index"),
      href: "/report/template",
    },
    {
      name: selectedTemplate.name,
    },
  ];

  const removeTemplateHandle = () => {
    try {
      dispatch(deleteTemplate({ id: parseInt(id) }, navigate));
      closeConfirmationRemoveButton();
    } catch (err) {
      console.log(err);
    }
  };

  const headers = columns.map((column) => ({
    label: column.headerName,
    key: column.field,
  }));

  const buttons = [
    (isAdmin ||
      isManager ||
      isEditor ||
      user.id === selectedTemplate?.created_by?.id) && {
      title: "แชร์",
      type: "button",
      variant: "contained",
      onClick: () => setShare(true),
    },
    {
      title: t("button.export"),
      type: "menuButton",
      variant: "outlined",
      csvFilename: `Template-${selectedTemplate.name}.csv`,
      excelFilename: `Template-${selectedTemplate.name}.xlsx`,
    },
    (isAdmin || isManager || isEditor) && {
      title: t("button.import"),
      type: "button",
      variant: "contained",
      onClick: () => setOpen(true),
    },
    {
      type: "iconMenuButton",
      headers: headers,
      filename: `Template-${selectedTemplate.name}.csv`,
      disabled: {
        download: isViewer && !isEditor && !isManager && !isAdmin,
        copy: isViewer && !isEditor && !isManager && !isAdmin,
        edit: isViewer && !isEditor && !isManager && !isAdmin,
        delete: (isViewer || isEditor) && !isManager && !isAdmin,
      },
    },
  ];

  const formatData = useMemo(() => {
    if (columns.length === 0) return;
    if (columns[0].type === "Month") return "MMM YYYY";
    else if (columns[0].type === "Year") return "YYYY";
    else if (columns[0].type === "Datetime") return "DD MMM YYYY";
    else return;
  }, [columns]);

  useEffect(() => {
    mobile.setMobileTitle(selectedTemplate.name);
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile, t, formatData, selectedTemplate.name]);

  useEffect(() => {
    dispatch(getTemplateById({ id: id }));

    return () => {
      dispatch(templateActions.removeSelectedTemplete());
      dispatch(templateActions.removeSumSelectedTemplete());
      dispatch(templateActions.resetFormState());
    };
  }, [dispatch, id]);

  const onFirstDataRendered = useCallback(
    (params) => {
      let createRangeChartParams = {
        cellRange: {
          columns: columns.map((col) => col.field.replaceAll(/\./g, "")),
        },
        suppressChartRanges: true,
        chartType: chart_type,
        chartContainer: document.querySelector("#myChart"),
      };
      params.api.createRangeChart(createRangeChartParams);
    },

    [chart_type, columns]
  );

  const closeHandler = () => {
    setOpen(false);
    dispatch(getTemplateById({ id: id }));
  };

  const closeConfirmation = () => {
    setConfirmation(false);
  };

  const openConfirmationRemoveButton = () => {
    setConfirmationRemoveButton(true);
  };

  const closeConfirmationRemoveButton = () => {
    setConfirmationRemoveButton(false);
  };

  const closeConfirmationCopyButton = () => {
    setOpenCopy(false);
  };

  const copyTemplateAction = async () => {
    const input = {
      ...selectedTemplate,
      name: `${selectedTemplate.name} - Copy`,
      columns: selectedTemplate.columns.map((obj) => {
        return {
          name: obj.headerName,
          data_type: obj.type === "numericColumn" ? "Number" : obj.type,
          can_override_value: obj.can_override_value,
        };
      }),
    };
    delete input.id;
    delete input.dataId;
    delete input.rows;
    delete input.role_permission_list;
    delete input.created_by;

    try {
      dispatch(createTemplate({ input }, navigate, true));
      closeConfirmationCopyButton();
    } catch (err) {
      console.log("err", err);
    }
  };

  const rowSelection = "multiple";
  const onSelectionChanged = (event) => {
    setDataSelector(event.api.getSelectedRows());
  };

  const deleteButtonOnclick = () => {
    dispatch(removeTemplateDatum(dataSelector));
    setDataSelector([]);
    setConfirmationRemoveButton(false);
  };

  const formatColumn = columns.map((column, index) => {
    const findFilterParams = (type) => {
      switch (type) {
        case "Datetime":
          return filterParams("Datetime");
        case "Month":
          return filterParams("Month");
        case "Year":
          return filterParams("Year");
        default:
          return;
      }
    };
    if (index !== 0) {
      if (column.type === "numericColumn") {
        return {
          headerName: column.headerName,
          field: column.field.replaceAll(/\./g, ""),
          type: column.type,
          chartDataType: "series",
          valueFormatter: (params) => toLocale(params),
        };
      } else {
        return {
          headerName: column.headerName,
          field: column.field,
          filter: "agMultiColumnFilter",
          filterParams: findFilterParams(column.type),
          chartDataType: "category",
        };
      }
    } else {
      if (
        column.type === "Month" ||
        column.type === "Year" ||
        column.type === "Datetime"
      ) {
        return {
          headerName: column.headerName,
          field: column.field,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          filter: "agMultiColumnFilter",
          filterParams: findFilterParams(column.type),
          chartDataType: "category",
          sort: "asc",
          width: 250,
        };
      } else {
        return {
          headerName: column.headerName,
          field: column.field,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          chartDataType: "category",
          sort: "asc",
        };
      }
    }
  });

  const components = useMemo(() => {
    if (columns.length > 0 && columns[0].type === "Month") {
      return {
        agDateInput: CustomMonth,
      };
    } else if (columns.length > 0 && columns[0].type === "Year") {
      return {
        agDateInput: CustomYear,
      };
    } else {
      return {
        agDateInput: CustomDate,
      };
    }
  }, [columns]);

  const closeEditHandler = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    if (rows.length > 0 && (has_sum_footer || has_avg_footer)) {
      dispatch(
        templateActions.loadSumSelectedData({
          rows: rows,
          sum: has_sum_footer,
          average: has_avg_footer,
        })
      );
    }
  }, [dispatch, has_avg_footer, has_sum_footer, rows]);

  const onFilterChanged = useCallback(
    (params) => {
      let filteredRows = [];
      params.api.forEachNodeAfterFilter((rowNode) => {
        filteredRows.push(rowNode.data);
      });
      if (filteredRows.length > 0) {
        dispatch(
          templateActions.loadSumSelectedData({
            rows: filteredRows,
            sum: has_sum_footer,
            average: has_avg_footer,
          })
        );
      }
    },
    [dispatch, has_avg_footer, has_sum_footer]
  );

  const closeShare = () => {
    setShare(false);
  };

  const checkDateTime = () => {
    if (
      selectedTemplate.last_updated_time &&
      selectedTemplate.last_updated_time !== "Invalid date"
    )
      return `อัพเดทเมื่อ ${moment(
        selectedTemplate.last_updated_time
      ).fromNow()}`;
  };

  return (
    <>
      {!isMobile && !isLandscape && (
        <DesktopLayout isSidebarOpen>
          <ActionBar
            ref={gridRef}
            headers={headers}
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            setConfirmation={setConfirmation}
            setOpenEdit={setOpenEdit}
            setOpenCopy={setOpenCopy}
            updateTime={checkDateTime()}
            isSidebarOpen
            isLoading={isLoading.selectedTemplate}
          />
          {!isLoading.selectedTemplate && (
            <AgGrid
              ref={gridRef}
              rowData={rows ?? []}
              components={components}
              columnDefs={formatColumn}
              columns={columns}
              enableCharts={true}
              onFirstDataRendered={onFirstDataRendered}
              sumSelectedTemplateData={sumSelectedTemplateData}
              isLoading={isLoading.selectedTemplate}
              has_avg_footer={has_avg_footer}
              has_sum_footer={has_sum_footer}
              onFilterChanged={onFilterChanged}
              rowSelection={rowSelection}
              deleteButtonOnclick={openConfirmationRemoveButton}
              buttonDelete={dataSelector.length > 0 && true}
              onSelectionChanged={onSelectionChanged}
              formatData={formatData}
              height={440}
            />
          )}
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout
          isLoading={isLoading.selectedTemplate}
          template
          headers={headers}
          setConfirmation={setConfirmation}
          setOpenEdit={setOpenEdit}
          setOpenCopy={setOpenCopy}
          disabled={{
            download: isViewer && !isEditor && !isManager && !isAdmin,
            copy: isViewer && !isEditor && !isManager && !isAdmin,
            edit: isViewer && !isEditor && !isManager && !isAdmin,
            delete: (isViewer || isEditor) && !isManager && !isAdmin,
          }}
          updateTime={checkDateTime()}
        >
          <Box sx={{ my: 2 }}>
            {!isLoading.selectedTemplate && (
              <AgGrid
                ref={gridRef}
                rowData={rows ?? []}
                components={components}
                columnDefs={formatColumn}
                columns={columns}
                enableCharts={true}
                onFirstDataRendered={onFirstDataRendered}
                sumSelectedTemplateData={sumSelectedTemplateData}
                isLoading={isLoading.selectedTemplate}
                has_avg_footer={has_avg_footer}
                has_sum_footer={has_sum_footer}
                onFilterChanged={onFilterChanged}
                rowSelection={rowSelection}
                deleteButtonOnclick={openConfirmationRemoveButton}
                buttonDelete={dataSelector.length > 0 && true}
                onSelectionChanged={onSelectionChanged}
                formatData={formatData}
                height={440}
                isMobile
              />
            )}
            <StyledButton
              sx={{ mb: 1 }}
              title={t("button.share")}
              fullWidth
              variant="contained"
              onClick={() => setShare(true)}
            />
            <StyledButton
              sx={{ mb: 1 }}
              title={t("button.import")}
              variant="contained"
              onClick={() => setOpen(true)}
              fullWidth
            />
            <MobileExport ref={gridRef} />
          </Box>
        </MobileLayout>
      )}
      {!isMobile && isLandscape && (
        <LandscapeLayout template>
          <AgGrid
            ref={gridRef}
            rowData={rows ?? []}
            components={components}
            columnDefs={formatColumn}
            columns={columns}
            enableCharts={true}
            onFirstDataRendered={onFirstDataRendered}
            sumSelectedTemplateData={sumSelectedTemplateData}
            isLoading={isLoading.selectedTemplate}
            has_avg_footer={has_avg_footer}
            has_sum_footer={has_sum_footer}
            onFilterChanged={onFilterChanged}
            rowSelection={rowSelection}
            deleteButtonOnclick={openConfirmationRemoveButton}
            buttonDelete={dataSelector.length > 0 && true}
            onSelectionChanged={onSelectionChanged}
            formatData={formatData}
            height={440}
            isMobile
          />
        </LandscapeLayout>
      )}
      <StyledModal
        startIcon={<FileDownloadOutlinedIcon fontSize="small" />}
        title={t("button.import")}
        content={
          <Box sx={{ width: 1000 }}>
            <TemplateImporter
              closeHandler={closeHandler}
              template_id={id}
              data_id={selectedTemplate.dataID && selectedTemplate.dataID}
            />
          </Box>
        }
        open={open}
        closeHandler={closeHandler}
      />
      <DeleteReportModal
        confirmation={confirmation}
        setConfirmation={setConfirmation}
        removeTemplateHandle={removeTemplateHandle}
        closeConfirmation={closeConfirmation}
      />
      <DeleteDataModal
        confirmationRemoveButton={confirmationRemoveButton}
        setConfirmationRemoveButton={setConfirmationRemoveButton}
        closeConfirmationRemoveButton={closeConfirmationRemoveButton}
        deleteButtonOnclick={deleteButtonOnclick}
      />
      <EditModal
        control={control}
        handleSubmit={handleSubmit}
        errors={errors}
        reset={reset}
        setOpenEdit={setOpenEdit}
        openEdit={openEdit}
        closeEditHandler={closeEditHandler}
        isMobile={isMobile}
      />
      <CopyModal
        setOpenCopy={setOpenCopy}
        copyTemplateAction={copyTemplateAction}
        openCopy={openCopy}
        closeConfirmationCopyButton={closeConfirmationCopyButton}
      />
      <ShareModal
        viewOnly={isEditor && isViewer && !isManager && !isAdmin}
        name={selectedTemplate.name}
        share={share}
        closeShare={closeShare}
      />
    </>
  );
};

export default TemplateDetail;
