import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import { clearSnackbar } from "../../features/ui/ui-actions";
import MuiAlert from "@mui/material/Alert";
import { Box } from "@mui/system";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarComponent() {
  const dispatch = useDispatch();

  const { snackbarMessage, snackbarOpen, snackbarType } = useSelector(
    (state) => state.ui
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  // const myType = ["success", "error", "info"];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={snackbarOpen}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Box>
        {snackbarType && (
          <Alert
            onClose={handleClose}
            severity={snackbarType || "info"}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        )}
      </Box>
    </Snackbar>
  );
}
