import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./i18n";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NavbarContextProvider } from "./contexts/navbar-context";
import { AuthContextProvider } from "./contexts/auth-context";
import StyledNotistack from "./components/Styled/StyledNotistack";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <StyledNotistack>
    <AuthContextProvider>
      <BrowserRouter>
        <Provider store={store}>
          <NavbarContextProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </NavbarContextProvider>
        </Provider>
      </BrowserRouter>
    </AuthContextProvider>
  </StyledNotistack>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
