import React, { useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import { useSelector, useDispatch } from "react-redux";
import "react-csv-importer/dist/index.css";
// import { templateActions } from "../../features/Template/template-slice";
import {
  // createTemplateData,
  updateTemplateData,
} from "../../features/Template/template-actions";
import moment from "moment";

const TemplateImporter = ({ closeHandler, template_id }) => {
  const dispatch = useDispatch();
  const { selectedTemplate } = useSelector((state) => state.template);
  const { columns, x_axis } = selectedTemplate;
  const [updatedRows, setUpdatedRows] = useState([]);

  return (
    <Importer
      className="importerContainer"
      assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
      restartable={false} // optional, lets user choose to upload another file when import is complete
      skipEmptyLines={true}
      delimiter=","
      processChunk={(rows) => {
        setUpdatedRows((prev) => [...prev, ...rows]);
      }}
      onComplete={() => {
        const units = columns.reduce(
          (obj, item) => Object.assign(obj, { [item.field]: item.type }),
          {}
        );
        const formatValue = (value, unit) => {
          if (unit === "numericColumn") {
            if (typeof value === "string") {
              const newValue = value.replaceAll(",", "");
              return parseFloat(newValue);
            } else return parseFloat(value);
          } else if (unit === "Datetime") {
            return moment(value).format("YYYY-MM-DD");
          } else if (unit === "Month") {
            return moment(value).format("YYYY-MM");
          } else if (unit === "Year") {
            return moment(value).format("YYYY");
          } else {
            return value;
          }
        };

        const formatRows = updatedRows.map((row) => {
          for (const key in row) {
            row[key] = formatValue(row[key], units[key]);
          }
          try {
            if (row[Object.keys(row)[0]] === "Invalid date") {
              throw new Error("ข้อมูลไม่ถูกต้อง ชนิดของข้อมูลไม่สัมพันธ์กัน");
            }
          } catch (err) {
            return console.log(err);
          }
          return row;
        });

        if (!formatRows.includes(undefined)) {
          const preSendData = formatRows.map((row) => {
            return Object.entries(row).map(([name, value], index) => {
              if (name.trim() === x_axis.trim()) {
                return {
                  name: name.trim(),
                  value: value,
                  can_override_value: true,
                };
              }
              return {
                name: name.trim(),
                value: value,
                can_override_value: false,
              };
            });
          });
          // if (rows.length === 0) {
          //   dispatch(createTemplateData(template_id, preSendData));
          // } else {
          dispatch(updateTemplateData(template_id, preSendData));
          // }
        }
      }}
      onClose={closeHandler}
    >
      {columns.map((col) => (
        <ImporterField
          key={col.field}
          name={col.field}
          label={col.headerName}
        />
      ))}
    </Importer>
  );
};

export default TemplateImporter;
