import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const CustomYear = forwardRef((props, ref) => {
  const [portal, setPortal] = useState(false);
  const [year, setYear] = useState(null);
  const refInput = useRef(null);
  const dateRef = useRef(null);

  const onDateChanged = (selectedDates) => {
    setYear(selectedDates);
    dateRef.current = selectedDates;
    props.onDateChanged();
  };

  useEffect(() => {
    if (refInput.current) {
      setPortal(true);
    }
  }, []);

  useEffect(() => {
    if (portal) {
      var element = document.getElementById("custom-year");
      if (element) element.classList.add("ag-custom-component-popup");
    }
  }, [portal]);

  useImperativeHandle(ref, () => ({
    getDate() {
      return dateRef.current;
    },
    setDate(date) {
      dateRef.current = date;
      setYear(date);
    },
  }));

  return (
    <DatePicker
      ref={refInput}
      selected={year}
      onChange={onDateChanged}
      showYearPicker
      dateFormat="yyyy"
      portalId="custom-year"
    />
  );
});

export default CustomYear;
