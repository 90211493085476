import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NavisionReport from "./pages/Report/Navision/NavisionReport";
import LandingReport from "./pages/Report/LandingReport";
import { theme } from "./theme";
import JobMonitor from "./pages/Monitor/JobMonitor";
import JobConfig from "./pages/Monitor/JobConfig";
import TemplateReport from "./pages/Report/Template/TemplateReport";
import SalesReport from "./pages/Report/Navision/SalesReport";
import SalesRegionReport from "./pages/Report/Navision/SalesRegionReport";
import SalesPersonReport from "./pages/Report/Navision/SalesPersonReport";
import CustomerReport from "./pages/Report/Navision/CustomerReport";
import ARReport from "./pages/Report/Navision/ARReport";
import APReport from "./pages/Report/Navision/APReport";
import CustomerReportDetail from "./pages/Report/Navision/CustomerReportDetail";
import TemplateDetail from "./pages/Report/Template/TemplateDetail";
import TemplateCreate from "./pages/Report/Template/TemplateCreate";
import SuccessSnackbar from "./components/Styled/StyledSnackbar";
import { useAuth } from "./hooks/use-auth";
import { Box, LinearProgress } from "@mui/material";
import Login from "./pages/Login";
import RequireAuth from "./pages/RequireAuth";
import UserAccount from "./pages/User/Account";
import UserContainer from "./pages/User/Account/Container";
import Rbac from "./pages/User/Rbac";
import UserLanding from "./pages/User";
import RbacContainer from "./pages/User/Rbac/Container";
import { RemoveTrailingSlash } from "./pages/RemoveTrailingSlash";
import Unauthorized from "./pages/Unauthorized";
import NotFound from "./pages/404";

function App() {
  const { isInitialized, isAuthenticated, user } = useAuth();
  const viewUserOnly = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("CONFIG__USER__VIEW") &&
      !role?.permission_list?.includes("CONFIG__USER__CREATE") &&
      !role?.permission_list?.includes("CONFIG__USER__EDIT") &&
      !role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );
  const viewRbacOnly = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("CONFIG__RBAC__VIEW") &&
      !role?.permission_list?.includes("CONFIG__RBAC__CREATE") &&
      !role?.permission_list?.includes("CONFIG__RBAC__EDIT") &&
      !role?.permission_list?.includes("CONFIG__RBAC__DELETE") &&
      !role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );
  const admin = "ADMIN__ADMIN__ADMIN";
  return (
    <>
      <ThemeProvider theme={theme}>
        {!isInitialized && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {isInitialized && (
          <>
            <RemoveTrailingSlash />
            <Routes>
              <Route
                path="/"
                element={
                  isInitialized && isAuthenticated ? (
                    <Navigate replace to="/report" />
                  ) : (
                    <Navigate replace to="/login" />
                  )
                }
              />
              <Route
                path="/login"
                element={
                  isInitialized && isAuthenticated ? (
                    <Navigate replace to="/report" />
                  ) : (
                    <Login />
                  )
                }
              />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route element={<NotFound />} path="*" />
              <Route
                element={
                  <RequireAuth
                    allowedPermissions={[admin, "GENERAL__GENERAL__VIEW"]}
                  />
                }
              >
                <Route path="/report">
                  <Route index element={<LandingReport />} />
                  <Route path="navision">
                    <Route index element={<NavisionReport />} />
                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={[admin, "NAVISION__SALES__VIEW"]}
                        />
                      }
                    >
                      <Route path="sales" element={<SalesReport />} />
                    </Route>
                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={[
                            admin,
                            "NAVISION__SALES_REGION__VIEW",
                          ]}
                        />
                      }
                    >
                      <Route
                        path="sales-region"
                        element={<SalesRegionReport />}
                      />
                    </Route>
                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={[
                            admin,
                            "NAVISION__SALES_PERSON__VIEW",
                          ]}
                        />
                      }
                    >
                      <Route
                        path="sales-person"
                        element={<SalesPersonReport />}
                      />
                    </Route>
                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={[
                            admin,
                            "NAVISION__CUSTOMER__VIEW",
                          ]}
                        />
                      }
                    >
                      <Route path="customer">
                        <Route index element={<CustomerReport />} />
                        <Route path=":id" element={<CustomerReportDetail />} />
                      </Route>
                    </Route>

                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={[admin, "NAVISION__AR__VIEW"]}
                        />
                      }
                    >
                      <Route path="ar" element={<ARReport />} />
                    </Route>
                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={[admin, "NAVISION__AP__VIEW"]}
                        />
                      }
                    >
                      <Route path="ap" element={<APReport />} />
                    </Route>
                  </Route>
                  <Route path="template">
                    <Route index element={<TemplateReport />} />
                    <Route path="create" element={<TemplateCreate />} />
                    <Route path=":id" element={<TemplateDetail />} />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedPermissions={[
                        admin,
                        "MONITOR__JOB__VIEW",
                        "MONITOR__JOB__CREATE",
                        "MONITOR__JOB__EDIT",
                        "MONITOR__JOB__DELETE",
                      ]}
                    />
                  }
                >
                  <Route path="/monitor">
                    <Route index element={<JobMonitor />} />
                    <Route path="config" element={<JobConfig />} />
                  </Route>
                </Route>
                <Route path="/user">
                  <Route index element={<UserLanding />} />
                  <Route path="config" element={<UserContainer setting />} />
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[
                          admin,
                          "CONFIG__RBAC__VIEW",
                          "CONFIG__RBAC__CREATE",
                          "CONFIG__RBAC__EDIT",
                          "CONFIG__RBAC__DELETE",
                        ]}
                      />
                    }
                  >
                    <Route path="rbac">
                      <Route index element={<Rbac />} />
                      <Route path="create" element={<RbacContainer />} />
                      <Route
                        path=":roleId"
                        element={<RbacContainer viewOnly={viewRbacOnly} />}
                      />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[
                          admin,
                          "CONFIG__USER__VIEW",
                          "CONFIG__USER__CREATE",
                          "CONFIG__USER__EDIT",
                          "CONFIG__USER__DELETE",
                        ]}
                      />
                    }
                  >
                    <Route path="admin">
                      <Route index element={<UserAccount />} />
                      <Route
                        path="create"
                        element={<UserContainer addable />}
                      />
                      <Route
                        path=":employeeId"
                        element={<UserContainer viewOnly={viewUserOnly} />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Routes>
          </>
        )}
        <SuccessSnackbar />
      </ThemeProvider>
    </>
  );
}

export default App;
