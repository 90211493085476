import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, LinearProgress } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2.5),
    paddingTop: "8px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2.5),
    paddingTop: "0",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, pt: 2.5, px: 0, width: "90%" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function StyledModal({
  startIcon,
  title,
  content,
  open,
  closeHandler,
  action,
  maxWidth,
  isLoading,
}) {
  return (
    <BootstrapDialog
      fullWidth={maxWidth}
      maxWidth={maxWidth || "lg"}
      onClose={closeHandler}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {isLoading && <LinearProgress />}
      <Box
        sx={{
          pl: 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        {startIcon}
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeHandler}
        >
          {title}
        </BootstrapDialogTitle>
      </Box>

      <DialogContent>{content}</DialogContent>
      {action && (
        <DialogActions sx={{ display: "block" }}>{action}</DialogActions>
      )}
    </BootstrapDialog>
  );
}
