import { Box, useMediaQuery } from "@mui/material";
import React, { useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import DesktopLayout from "../../../components/Layout/DesktopLayout";
import MobileLayout from "../../../components/Layout/MobileLayout";
import StyledTab from "../../../components/Styled/StyledTab";
import ActionBar from "../../../components/UI/ActionBar";
import AgGrid from "../../../components/UI/AgGrid";
import StyledButton from "../../../components/Styled/StyledButton";
import { useDispatch, useSelector } from "react-redux";
import { getTemplates } from "../../../features/Template/template-actions";
import { useNavbar } from "../../../hooks/use-navbar";
import CustomDate from "../../../components/UI/CustomDate";
import { filterParams } from "../../../utils/data-transformer";
import LandscapeLayout from "../../../components/Layout/LandscapeLayout";

const TemplateReport = () => {
  const { pathname } = useLocation();
  const gridRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mobile } = useNavbar();
  const { templates, isLoading } = useSelector((state) => state.template);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });

  const breadcrumbs = [
    {
      name: t("report.index"),
      href: "/report",
    },
    {
      name: t("report.template.index"),
    },
  ];

  const buttons = [
    {
      title: t("button.create"),
      type: "button",
      variant: "contained",
      onClick: () => navigate(`${pathname}/create`),
    },
  ];

  const tabs = [
    {
      label: t("report.navision.index"),
      path: "/report/navision",
    },
    {
      label: t("report.template.index"),
      path: "/report/template",
    },
  ];

  const columns = [
    {
      field: "name",
      headerName: t("report.template.reportName"),
      width: 400,
      sort: "asc",
    },
    {
      field: "createdAt",
      headerName: t("report.template.createdAt"),
      filter: "agMultiColumnFilter",
      filterParams: filterParams("Datetime"),
      width: 250,
    },
    {
      field: "last_updated_time",
      headerName: t("report.navision.lastUpdated"),
      filter: "agMultiColumnFilter",
      filterParams: filterParams("Datetime"),
      cellRenderer: (params) => (
        <>{params.value !== "Invalid date" && params.value}</>
      ),
      width: 250,
    },
  ];

  const getRowStyle = () => {
    return { cursor: "pointer" };
  };

  const onRowClicked = (event) => {
    return navigate(`${event.data.id}`);
  };

  useEffect(() => {
    mobile.setMobileTitle(t("report.template.index"));
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile, t]);

  useEffect(() => {
    dispatch(getTemplates({ limit: 999 }));
  }, [dispatch]);

  const components = useMemo(() => {
    return {
      agDateInput: CustomDate,
    };
  }, []);

  const isLandscape = useMediaQuery(
    "(max-device-width: 920px) and (orientation: landscape)",
    {
      noSsr: true,
    }
  );

  return (
    <>
      {!isMobile && !isLandscape && (
        <DesktopLayout isSidebarOpen pathname="/report">
          <ActionBar
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isSidebarOpen
            isLoading={isLoading.templates}
          />
          <StyledTab tabs={tabs} currentTab={pathname} />
          {!isLoading.templates && (
            <AgGrid
              ref={gridRef}
              rowData={templates}
              getRowStyle={getRowStyle}
              columnDefs={columns}
              onRowClicked={onRowClicked}
              searchBox
              height={600}
              components={components}
            />
          )}
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout isLoading={isLoading.templates} pathname="/report">
          <Box sx={{ width: "100%", mt: 2 }}>
            <StyledTab tabs={tabs} centered={isMobile} currentTab={pathname} />
            {!isLoading.templates && (
              <>
                <AgGrid
                  ref={gridRef}
                  rowData={templates}
                  columnDefs={columns}
                  onRowClicked={onRowClicked}
                  height={"65vh"}
                  components={components}
                  searchBox
                  isMobile
                />
                <StyledButton
                  fullWidth
                  sx={{ py: 1.5 }}
                  variant="contained"
                  title={"สร้าง"}
                  onClick={() => navigate("create")}
                />
              </>
            )}
          </Box>
        </MobileLayout>
      )}
      {!isMobile && isLandscape && (
        <LandscapeLayout>
          <Box sx={{ my: 2 }}>
            <ActionBar
              breakcrumbs={breadcrumbs}
              isLoading={isLoading.templates}
              isLandscape
            />
            <StyledTab
              tabs={tabs}
              currentTab={pathname}
              centered={isMobile}
              divider
            />
            {!isLoading.templates && (
              <Box>
                <AgGrid
                  ref={gridRef}
                  rowData={templates}
                  columnDefs={columns}
                  onRowClicked={onRowClicked}
                  height={450}
                  components={components}
                  isMobile
                />
              </Box>
            )}
          </Box>
        </LandscapeLayout>
      )}
    </>
  );
};

export default TemplateReport;
