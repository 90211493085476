import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import LoginForm from "../components/Form/Login";

const Login = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Box sx={{ width: isMobile ? 300 : 400 }}>
        <img src="/static/logo.png" alt="logo" />
        <LoginForm />
      </Box>
    </Box>
  );
};

export default Login;
