import { Box, Drawer, IconButton, useMediaQuery } from "@mui/material";
import { alpha } from "@mui/material/styles";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { LeftNavbarSection } from "./LeftNavbarSection";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { useNavbar } from "../../hooks/use-navbar";
import { useAuth } from "../../hooks/use-auth";

const LeftNavbar = () => {
  const { sidebar } = useNavbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();

  const sections = useMemo(() => {
    let allPermissions = [];

    user?.role_list?.forEach((role) => {
      allPermissions.push(...role.permission_list);
    });

    return [
      {
        title: t("report.index"),
        items: [
          {
            title: t("report.navision.index"),
            path: "/report/navision",
            icon: <DescriptionOutlinedIcon fontSize="small" />,
          },
          {
            title: t("report.template.index"),
            path: "/report/template",
            icon: <DescriptionOutlinedIcon fontSize="small" />,
          },
        ],
      },
      (allPermissions.includes("ADMIN__ADMIN__ADMIN") ||
        allPermissions.includes("MONITOR__JOB__VIEW") ||
        allPermissions.includes("MONITOR__JOB__CREATE")) && {
        title: t("monitor.index"),
        items: [
          {
            title: t("monitor.jobLog"),
            path: "/monitor",
            icon: <DashboardOutlinedIcon fontSize="small" />,
          },
          {
            title: t("monitor.jobConfig"),
            path: "/monitor/config",
            icon: <SettingsOutlinedIcon fontSize="small" />,
          },
        ],
      },
      {
        title: t("user.account.index"),
        items: [
          {
            title: t("user.config"),
            path: "/user/config",
            icon: <PermIdentityOutlinedIcon fontSize="small" />,
          },
          (allPermissions.includes("ADMIN__ADMIN__ADMIN") ||
            allPermissions.includes("CONFIG__RBAC__VIEW") ||
            allPermissions.includes("CONFIG__RBAC__CREATE") ||
            allPermissions.includes("CONFIG__RBAC__EDIT") ||
            allPermissions.includes("CONFIG__RBAC__DELETE")) && {
            title: t("user.rbac.index"),
            path: "/user/rbac",
            icon: <PrivacyTipOutlinedIcon fontSize="small" />,
          },
          (allPermissions.includes("ADMIN__ADMIN__ADMIN") ||
            allPermissions.includes("CONFIG__USER__VIEW") ||
            allPermissions.includes("CONFIG__USER__CREATE") ||
            allPermissions.includes("CONFIG__USER__EDIT") ||
            allPermissions.includes("CONFIG__USER__DELETE")) && {
            title: t("user.index"),
            path: "/user/admin",
            icon: <PeopleOutlineOutlinedIcon fontSize="small" />,
          },
        ],
      },
    ];
  }, [t, user?.role_list]);

  const fileredSections = sections.filter((section) => section !== false);
  const formatSections = fileredSections.map((section) => ({
    title: section.title,
    items: section.items.filter((item) => item !== false),
  }));

  const isNavClose = useMediaQuery((theme) => theme.breakpoints.down("lg"), {
    noSsr: true,
  });

  let renderCouter = useRef(0);

  useEffect(() => {
    if (isNavClose && renderCouter.current === 0) {
      sidebar.setIsSidebarOpen(false);
      renderCouter.current++;
    }
  }, [isNavClose, sidebar]);

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            pt: 3,
            pl: 3,
            display: "flex",
            gap: 1,
          }}
        >
          <img
            style={{ cursor: "pointer", width: 175 }}
            src="/static/logo.png"
            alt="logo"
            onClick={() => navigate("/")}
          />
          <IconButton
            sx={{
              height: "fit-content",
              color: (theme) => theme.palette.primary.main,
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.1),
              "&:hover": {
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.2),
              },
            }}
            onClick={() => sidebar.setIsSidebarOpen(false)}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {formatSections?.map((section) => (
            <LeftNavbarSection
              key={section.title}
              path={pathname}
              sx={{
                mt: 2,
                "& + &": {
                  mt: 2,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Box>
    </>
  );

  return (
    <Drawer
      anchor="left"
      open={sidebar.isSidebarOpen}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          borderRightColor: "divider",
          borderRightStyle: "solid",
          borderRightWidth: 1,
          width: 260,
        },
      }}
      variant="persistent"
    >
      {content}
    </Drawer>
  );
};

export default LeftNavbar;
